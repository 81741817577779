.sideMenu {
    width:20%;
    height: 100%;
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius:30px;
    background: var(--bgColor);
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    transition: 1s;
}

.sideMenu .logo {
    font-family: 'Bauhaus 93';
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: calc(15px);
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    font-size: calc(3.5em);
    font-weight: calc(600);
    letter-spacing: 1px;
    text-transform: uppercase;
}

.sideMenu .nav {
    margin-top: calc(30px);
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: calc(15px);
}

.sideMenu .nav li a {
    width:100%;
    text-decoration: none;
    color: #ffffff;
    font-size: calc(1.2em);
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    gap: calc(25px);
    transition: 0.5s;
    background: var(--bgColor);
    border-radius: 20px;
    padding: calc(10px) calc(20px);
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    transition: 0.5s;
}

.sideMenu .nav li a:hover,
.sideMenu .nav li a.active {
    box-shadow:  -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
}

.sideMenu.active {
    width: 7%;
    transition: 1s;
    overflow: hidden;
    align-items: center;
}

.sideMenu.active .brand,
.sideMenu.active .navName {
    display: none;
}

.sideMenu.active .nav li a {
    font-size: 1.8em;
}

.sideMenu .nav li a:hover,
.sideMenu .nav li a.active {
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.25);
}