.gameCard .gameRating {
    font-size: 1.3em;
    text-shadow: 0px 0px 10px #212529, 0px 0px 15px var(--bgColor);
}
.gameCard .gameRating i.active {
    color: var(--second);
    text-shadow: 0px 0px 10px var(--second), 0px 0px 15px var(--bgColor);
}

.gameCard .gameRating i {
    margin-left: 2px;
}