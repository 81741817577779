.gamePage{
    position: relative;
    width: 100%;
    height:100%;
    background: var(--bgColor);
}

.gamePage img {
    height: 50%;
    width: 100%;
    margin: 65px 0;
    margin-bottom: 15px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    transition: 0.1s;
}

.gamePage .like {
    position: absolute;
    top:64px;
    left:calc(100% - 80px);
    width:40px;
    height: 40px;
    background: var(--bgColor);
    padding: 5px;
    border-radius: 0 0 0 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    transition: 0.3s;
}

.gamePage .like.active {
    color: var(--second);
    text-shadow: 0px 0px 10px var(--second), 0px 0px 15px var(--bgColor);
}

.gamePage span {
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.gamePage .gameFeature {
    display: flex;
    justify-content: space-between;
}

.gamePage .gameType{
    background: var(--second);
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.gamePage .gameTitle {
    top:0;
    width: 100%;
    position: absolute;
    font-family: 'Bauhaus 93';
    text-transform: uppercase;
    font-size: 2.3rem;
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.gamePage .gameDisc {
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    gap:20px;
}

.gamePage .buttons {
    display: inline-flex;
    align-items: center;
    gap: 20px;
}

.gamePage .About .AboutTitle {
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    font-size: 1.5rem;
}

.gamePage .buttons a {
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
}
.gamePage .buttons .orderBtn {
    padding: 8px 20px;
    background: var(--second);
    border-radius: 10px;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    box-shadow: 0px 0px 10px var(--second), 0px 0px 15px rgba(0,0,0,0);
    transition: 0.3s;
}
.gamePage .buttons .orderBtn:hover {
    background: var(--primary);
    box-shadow: 0px 0px 10px var(--primary), 0px 0px 15px rgba(0,0,0,0);
}


.gamePage .addBag {
    position: absolute;
    top:64px;
    left:calc(100% - 40px);;
    width:40px;
    height: 40px;
    color: #ffffff;
    background-color: var(--bgColor);
    padding:10px;
    display: flex;
    justify-content: center;
    border-radius: 0 10px 0 0;
    align-items: center;
    font-size: 1.2rem;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    transition: 0.3s;
}

.gamePage .addBag:hover{
    background-color: var(--primary);
}
.gamePage .like:hover{
    background-color: var(--primary);
}