header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content:  space-between;
    align-items: center;
    padding: 20px 30px;
    z-index: 1000 !important;
    background: transparent;
}

.menu {
    font-size: 1.8rem;
    color:#ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.userItems {
    display: inline-flex;
    align-items:center;
    gap:20px;
}

.userItems .icon {
    position: relative;
    color: #ffffff;
    font-size: 1.5rem;
    width: 50px;
    height:50px;
    border-radius: 10px;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}
.userItems .icon .like,
.userItems .icon .bag {
    position: absolute;
    right:0;
    bottom: 10px;
    font-size: 0.8rem;
    font-weight: 800;
    height: 20px;
    width: 20px;
    padding: 3px;
    color: #ffffff;
    background: var(--second);
    border-radius:50%;
    display: flex;
    justify-content:  center;
    align-items: center;


}


.userItems .avatar {
    width: 150px;
    height: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap:15px;
    border-radius: 10px;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
}

.userItems .avatar img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    background-color: #ffffff;
}

.userItems .avatar a {
    color: #ffffff;
    font-size: 1.8rem;
}
.userItems .avatar .user {
    display: flex;
    flex-direction: column;
}

.userItems .avatar .user span {
    color: #ffffff;
    font-size: 0.7rem;
}

.userItems .avatar .user a {
    text-decoration: none;
    font-size: 0.6rem;
}