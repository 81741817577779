.filters {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 15px;
    margin: 20px 0 30px 0;
    padding: 0;
}

.filters li {
    color: #ffffff;
    padding: 10px 25px;
    transition: 0.3s;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.filters li:hover,
.filters li.active {
    box-shadow: -5px -5px 10px rgba(255,255,255,0.1), 5px 5px 10px rgba(0,0,0,0.35);
}

.search {
    color: #9c9c9c;
    display: inline-flex;
    gap:15px;
    align-items: center;
    width:300px;
    height:50px;
    padding: 8px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
    text-shadow: 0px 0px 10px #9c9c9c, 0px 0px 15px var(--second);
}

.search input {
    outline: none;
    border: none;
     background: transparent;
     font-size: 1.2rem;
}

.search input:focus {
    color: #ffffff;
}
.search input::placeholder {
    color: #9c9c9c;
}