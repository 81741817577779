.gameCard{
    position: relative;
    padding: 25px 20px;
    margin-bottom: 25px;
    width: auto;
    height:400px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 20px;
    background: var(--bgColor);
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
}

.gameCard img {
    border-radius: 20px;
    margin-bottom: 15px;
    object-fit: cover;
    object-position: center;
    height: 50%;
    width: 100%;

}

.gameCard .like {
    position: absolute;
    top:24px;
    right:19px;
    width:40px;
    height: 40px;
    background: var(--bgColor);
    padding: 5px;
    border-radius: 0 0 0 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    transition: 0.3s;
}

.gameCard .like.active {
    color: var(--second);
    text-shadow: 0px 0px 10px var(--second), 0px 0px 15px var(--bgColor);
}

.gameCard span {
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.gameCard .gameFeature {
    display: flex;
    justify-content: space-between;
}

.gameCard .gameType{
    background: var(--second);
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.gameCard .gameTitle {
    margin-top: 10px;
    font-family: 'Bauhaus 93';
    text-transform: uppercase;
    font-size: 1.3rem;
    color: #ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.gameCard .buttons {
    display: inline-flex;
    align-items: center;
    gap: 20px;
}

.gameCard .buttons a {
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
}
.gameCard .buttons .orderBtn {
    padding: 8px 20px;
    background: var(--second);
    border-radius: 10px;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    box-shadow: 0px 0px 10px var(--second), 0px 0px 15px rgba(0,0,0,0);
    transition: 0.3s;
}

.gameCard .buttons .orderBtn:hover {
    background-color: var(--primary);
    box-shadow: 0px 0px 10px var(--primary), 0px 0px 15px rgba(0,0,0,0);
}

.gameCard .gamePrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gameCard .discount {
    background: #ff0000;

    border-radius: 5px;
    font-size: 1rem;
    font-weight: 800;
    text-shadow: 0px 0px 10px white, 0px 0px 15px #ff0000;
    box-shadow: 0px 0px 10px #ff0000, 0px 0px 15px var(--second);
}

.gameCard .prevPrice {
    text-decoration: line-through;
    text-decoration-color: #ff0000;
    text-decoration-thickness: 3px;
    font-size: 0.8rem;
}
.gameCard .currentPrice {
    font-size: 1.2rem;
    font-weight: 800;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}
.gameCard .addBag {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #ffffff;
    background-color: var(--second);
    padding:10px;
    width: 40px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 0 20px 0;
    font-size: 1.2rem;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    transition: 0.3s;
}

.gameCard .addBag:hover{
    background-color: var(--primary);
}