@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&damily=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('//db.onlinewebfonts.com/c/89d11a443c316da80dcb8f5e1f63c86e?family=8auhaus+93');

* {
  margin:0 ;
  padding:0;
  box-sizing: border-box;
}

:root {
  --primary: #0d171f;
  --second:#0998a8;
  --bgColor: #192938;
}

h1,h2,h4,p {
  color: #ffffff;
}

body {
  position: absolute;
  background: var(--bgColor);
  font-family:  'Roboto', sans-serif;
  width: 100%;

  min-height: 100vh;
  overflow: hidden;
}

.loading{
  text-align: center;
  align-self: center;
  width:100%;
  position: absolute;
  top:calc(50% - 0px);
  font-size: 0px;
  font-family: 'Bauhau';
  transition: 0.5s;
  text-shadow: 0px 0px 15px white, 0px 0px 20px var(--second), 0px 0px 25px var(--primary);
  animation: growText 1s ease-out forwards; /* Animation for growing text */
}

@keyframes growText {
  from {
    font-size: 0;
    top:calc(50% - 0px);
  }

  to {
    font-size: 100px;
    top:calc(50% - 100px);
  }
}
